@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* Global styles */

/* Body styles */
/* Global styles */

/* Body styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: hsl(180, 86%, 94%);
    color: #333333;
    line-height: 1.6;
  }
  
  /* Container styles */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  /* Section styles */
  section {
    padding: 2rem 0;
  }
  
  /* Heading styles */
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333333;
  }
  
  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #333333;
  }
  
  /* Paragraph styles */
  p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  /* Button styles */
  .button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
    background-color: #61dafb;
    color: #ffffff;
  }
  
  .button:hover {
    background-color: #4fbfe6;
  }
  
  /* Link styles */
  a {
    color: #61dafb;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #4fbfe6;
  }
  
  /* Image styles */
  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .img-container img {
    max-width: 100%;
    height: auto;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Card styles */
  .card {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1.5rem;
  }
  
  /* Form styles */
  .form {
    margin-bottom: 1rem;
  }
  
  .form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  
  .form input,
  .form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #8b1212;
    border-radius: 0.25rem;
    transition: border-color 0.3s ease;
  }
  
  .form input:focus,
  .form textarea:focus {
    outline: none;
    border-color: #1d6f72;
  }
  
  /* Navigation styles */
  .navbar {
    background-color: #2e0b0b;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-brand {
    font-size: 1.25rem;
    font-weight: 700;
    color: #333333;
  }
  
  .navbar-nav {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .navbar-nav li {
    margin-left: 1rem;
  }
  
  .navbar-nav li a {
    color: #333333;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
  }
  
  .navbar-nav li a:hover {
    color: #61dafb;
  }
  
  /* Footer styles */
  .footer {
    background-color: #333333;
    padding: 2rem 0;
    color: #ffffff;
    text-align: center;
  }
  
  .footer-text {
    font-size: 0.875rem;
  }
  
  .footer-link {
    color: #61dafb;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #4fbfe6;
  }

  .fadeIn {
    animation: 1s fadeIn forwards;
  }
  
  .fadeOut {
    animation: 1s fadeOut forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-30px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(-30px, 0);
      opacity: 0;
    }
  }
  
  .font-link {
    font-family: 'Yesteryear', cursive;
  }

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./images/navbar.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.08; /* Adjust the opacity value to make it more or less transparent */
    z-index: -1; /* Make sure the image is positioned behind other elements */
  }

